<!--
// nuxt-ui/components/Shared/slider-matches/SliderMatches.vue
-->
<script setup lang="ts">
import SliderMatchesTurn from "~/nuxt-ui/components/Shared/slider-matches/SliderMatchesTurn.vue";
import type { Team } from "~/src/team/domain/models/Team";
import type { MatchCenterData } from "~/src/match/domain/models/Match";
import { arrowsSliderVisibility } from "~/src/Shared/utils";

type Props = {
  phaseTurns: MatchCenterData[];
  teams: Record<number, Team>;
  regionReference?: number;
};

const props = defineProps<Props>();
const scrolling = ref<boolean>(true);

function setFirstSliderElement(element: HTMLElement | null): void {
  const matchesContainer: HTMLElement | null = element;
  const buttonLeft: HTMLElement | null = document.getElementById("buttonLeft");
  const buttonRight: HTMLElement | null = document.getElementById("buttonRight");

  if (matchesContainer) {
    const activeMatch: HTMLElement | null = matchesContainer.querySelector(".card-match.live-match");
    const lastMatchPlayed: HTMLElement | null = matchesContainer.querySelector(
      ".card-match[data-last-played]"
    );
    matchesContainer.scrollLeft = 0;

    if (activeMatch && matchesContainer) {
      const liveMatchX = activeMatch.getBoundingClientRect().x;
      if (window.innerWidth < 1200) {
        //scroll to match on mobile
        matchesContainer.scrollLeft = liveMatchX - 24;
      } else {
        //scroll to third, the active match for Desktop
        matchesContainer.scrollLeft = liveMatchX - 324;
      }
    } else if (lastMatchPlayed && matchesContainer) {
      matchesContainer.scrollLeft = lastMatchPlayed.getBoundingClientRect().x - 24;
    }

    let childrenMatchesContainerWidth: number = 0;
    const childrenMatchesContainer = [...matchesContainer.children];
    childrenMatchesContainer.forEach(item => (childrenMatchesContainerWidth += item.clientWidth));

    if (buttonRight && buttonLeft && childrenMatchesContainerWidth < window.innerWidth) {
      buttonRight.classList.add("hidden");
      buttonLeft.classList.add("hidden");
    }

    matchesContainer.addEventListener("scroll", () => {
      arrowsSliderVisibility("general", matchesContainer, buttonLeft, buttonRight);
    });
  }
}

onMounted(() => {
  const sliderMatches = document.getElementById("matchesSlider");
  if (sliderMatches) {
    setFirstSliderElement(sliderMatches);
  }
  scrolling.value = false;
});
</script>

<template>
  <div id="matchesSlider" class="hide-scrollbar matches-slider-wrapper" :class="{ 'blur-sm': scrolling }">
    <slider-matches-turn
      v-for="(turn, index) in phaseTurns"
      :key="index"
      :turn="turn"
      :teams="teams"
      :region-reference="regionReference"
    ></slider-matches-turn>
  </div>
</template>

<style scoped>
.matches-slider-wrapper {
  @apply relative w-full max-w-full overflow-x-auto flex flex-nowrap;
}
</style>
